<section class="relative pt-16 bg-blueGray-50">
  <div class="container mx-auto">
    <div class="flex flex-wrap items-center">
      <div
        class="w-10/12 md:w-6/12 lg:w-4/12 md:px-12 md:px-4 mr-auto ml-auto
        -mt-78">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6
          shadow-lg rounded-lg bg-indigo-500">
          <img
            alt="about-us"
            src="assets/3d-rendering-ventilation-system.jpg"
            class="w-full align-middle rounded-t-lg" />
          <blockquote class="relative p-8 mb-4">
            <!-- <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              class="absolute left-0 w-full block h-95-px -top-94-px">
              <polygon
                points="-30,95 583,95 583,65"
                class="text-pink-500 fill-current" />
            </svg> -->
            <h4 class="text-xl font-bold text-white">About Us</h4>
            <p class="text-md font-light mt-2 text-white">
              We would like to introduce ourselves as distributors for the
              various products in airconditioning and insulation industry. We
              have been serving this industry since last 22 years. Right from
              the first day, till now we belive in quality, hard work, loyalty
              and prompt service to the customers.
            </p>
          </blockquote>
        </div>
      </div>
      <!-- Here are some products we deals in:: We hope you will find
      our products according to your requirements and would favour us with your
      valued order. For any enquiries about any of the above products, please
      share your enquiry or BOQ with us here: si.snowcool@yahoo.in
      si.snowcoolindia@gmail.com -->

      <div class="w-full md:w-6/12 px-4">
        <div class="flex bg-indigo-500 p-10 rounded-lg text-white">
          <div class="mb-auto mt-auto max-w-lg">
            <h1 class="text-3xl uppercase">Snow cool India</h1>
            <p class="font-semibold mb-5">Products we deal in:</p>
            <p>
              1) Nitrile Rubber Sheet 2) Nitrile Rubber Tube 3) Adhesives (
              Fevicol / Polygrip) 4) InsuSound (acoustic ) / Glasswool 5) XLPE
              Sheet 6) Threaded Rod 7) C Channel / Cable Tray 8) Fashner /nut
              bolts/ Clamps / Screws 9) PVC PIpe & Fittings 10) AC Stand 11) UV
              Coating 12) Glass Cloth 13) Electrical Conduits 14) Insulated &
              Non Insulated Ducts 15) PVC/ Aluminium/VRVTapes, etc.
            </p>
            <button class="bg-black rounded-md py-3 px-7 mt-6 text-white">
              Email Us
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
