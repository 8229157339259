<style>
  ul {
    list-style: none;
  }
  li {
  }
  .itemList {
    padding: 20px;
    box-shadow: 2px 2px 2px #ebebeb;
  }
  .flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .margin-5 {
    margin: 5px;
  }
  .w-full {
    width: 100%;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
</style>

<!-- <nav class="bg-white shadow-lg">
    <div class="md:flex items-center justify-between py-2 px-8 md:px-12">
      <div class="flex justify-between items-center">
        <div class="text-2xl font-bold text-gray-800 md:text-3xl">
          <a href="#">Snow Cool India</a>
        </div>
        <div class="md:hidden">
          <button
            type="button"
            class="block text-gray-800 hover:text-gray-700 focus:text-gray-700
            focus:outline-none">
            <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path
                class="hidden"
                d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0
                1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12
                10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z" />
              <path
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0
                2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex flex-col md:flex-row hidden md:block -mx-2">
        <a
          href="#"
          class="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100
          hover:font-medium py-2 px-2 md:mx-2">
          Home
        </a>
        <a
          href="#"
          class="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100
          hover:font-medium py-2 px-2 md:mx-2">
          About
        </a>
        <a
          href="#"
          class="text-gray-800 rounded hover:bg-gray-900 hover:text-gray-100
          hover:font-medium py-2 px-2 md:mx-2">
          Contact
        </a>
      </div>
    </div>
  </nav>







 -->
<div class="w-full">

  <div class="flex bg-white flex-wrap " style="height:600px;">
    <div
      class="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2
      sm:flex-1 sm:order-first order-last">
      <div>
        <h2 class="text-3xl font-semibold text-gray-800 md:text-4xl">
          Complete Insulation Solutions &
          <span class="text-indigo-600">Ancillaries</span>
        </h2>
        <p class="mt-2 text-sm text-gray-500 md:text-base">
          Wholesaler of Hot and Cold Insulation, Adhesive, Thermal Insualtion
          Protective Coating, Industrial Sealants, Fiber Glass Tissue,
          Industrial Paint, Insulation Accessories
        </p>
        <!-- <div class="flex justify-center lg:justify-start mt-6">
          <a
            class="px-4 py-3 bg-gray-900 text-gray-200 text-xs font-semibold
            rounded hover:bg-gray-800"
            href="#">
            Get Started
          </a>
          <a
            class="mx-4 px-4 py-3 bg-gray-300 text-gray-900 text-xs
            font-semibold rounded hover:bg-gray-400"
            href="#">
            Learn More
          </a>
        </div> -->
      </div>
    </div>
    <div class="lg:w-1/2 sm:flex-1 ">
      <img class="w-full object-fit" src="assets/main.jpg" />

    </div>
  </div>
</div>

<section class="container mx-auto px-6 p-10 mt-10">
  <h2 class="text-4xl font-bold text-center text-gray-800 mb-8">Catalogue</h2>

  <!-- <div
    class="flex items-center flex-wrap mb-20 bg-white-1 px-4 py-4 rounded-lg">
    <div class="w-full md:w-1/2 flex justify-center">
      <img
        src="assets/snow-cool-india-nitrile-rubber-sheet.png"
        alt="Nitrile Sheets" />
    </div>
    <div class="w-full md:w-1/2 md:pl-10">
      <h4 class="text-3xl text-gray-800 font-bold mb-3">Nitrile Sheets</h4>
      <p class="text-gray-600 mb-8">
        Nitrile rubber or acrylonitrile butadiene rubber is a co-polymer of
        butadiene and acrylonitrile. It has good general resistance to oil along
        with good mechanical properties, especially tensile strength, flexing,
        compression set and impermeability to gases. It has moderate aging
        properties and good adhesion to metal. Its recommended operating
        temperature range is -30° C to + 120° C. It also displays a good
        resistance to inorganic chemical products except antioxidant agents and
        chlorine. It gives satisfactory resistance to general hydrocarbons. Due
        to its polar nature, we do not recommend its use with polar liquids like
        ketones, ethers and amines.
      </p>
    </div>
  </div> -->

  <div
    class="md:p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3
    xl:grid-cols-3 gap-5">
    <!--Card 1-->
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-nitrile-rubber-sheet.png"
        alt="Nitrile Sheets" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            Nitrile Sheets
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          Nitrile rubber or acrylonitrile butadiene rubber is a co-polymer of
          butadiene and acrylonitrile. It has good general resistance to oil
          along with good mechanical properties, especially tensile strength,
          flexing, compression set and impermeability to gases. It has moderate
          aging properties and good adhesion to metal. Its recommended operating
          temperature range is -30° C to + 120° C. It also displays a good
          resistance to inorganic chemical products except antioxidant agents
          and chlorine. It gives satisfactory resistance to general
          hydrocarbons. Due to its polar nature, we do not recommend its use
          with polar liquids like ketones, ethers and amines.
        </p>
      </div>

    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-aluminium-foil-glass-wool-insulation.png"
        alt="Glass wool insulation" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            Glass wool insulation
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          Glass wool is a thermal insulation material consisting of intertwined
          and flexible glass fibers, which causes it to "package" air, resulting
          in a low density that can be varied through compression and binder
          content (as noted above, these air cells are the actual insulator).
          Glass wool can be a loose-fill material, blown into attics, or
          together with an active binder, sprayed on the underside of
          structures, sheets, and panels that can be used to insulate flat
          surfaces such as cavity wall insulation, ceiling tiles, curtain walls,
          and ducting. It is also used to insulate piping and for soundproofing.
        </p>
      </div>

    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-power-steel-full-threaded-rod.jpg"
        alt="Full Threaded Rod" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            Full Threaded Rod
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          A threaded rod, also known as a stud, is a relatively long rod that is
          threaded on both ends; the thread may extend along the complete length
          of the rod.They are designed to be used in tension. Threaded rod in
          bar stock form is often called all-thread.
        </p>
      </div>

    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-synthetic-rubber-adhesive.png"
        alt="Pidilite" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            Pidilite
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          Pidilite is a synthetic rubber based solvent containing contact
          adhesive for Multipurpose applications. It exhibits immediate bond
          strength and has good resistance to water and aging.
        </p>
      </div>
    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-industrial-pvc-pipe.jpg"
        alt="PVC pipe" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            PVC pipe
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          Polyvinyl chloride is the world's third-most widely produced synthetic
          plastic polymer, after polyethylene and polypropylene. About 40
          million tonnes are produced per year. PVC comes in two basic forms:
          rigid and flexible
        </p>
      </div>

    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-anchor-fasteners.png"
        alt="Anchor Fasteners" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            Anchor Fasteners
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          Anchor bolts are used to connect structural and non-structural
          elements to the concrete. The connection is made by an assembling of
          different components such as: anchor bolts (also named fasteners),
          steel plates, stiffeners. Anchor bolts transfer different types of
          load: tension forces and shear forces. A connection between structural
          elements can be represented by steel column attached to reinforced
          concrete foundation. Whereas, a common case of non-structural element
          attached to a structural one is represented by the connection between
          a facade system and a reinforced concrete wall.
        </p>
      </div>

    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-xlpe-foam-sheet.png"
        alt="XLPE Foam Sheet" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            XLPE Foam Sheet
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          XLPE is chemically crosslinked PE foam. It is produced in continuous
          rolls resulting in a foam product with uniform, closed cells and
          smooth skins on both sides. It is light weight, flexible and soft to
          touch, yet strong, tough, resilient and resistant to moisture, many
          chemicals and temperature extremes.
        </p>
      </div>

    </div>
    <div class="max-w-sm rounded overflow-hidden shadow-lg">
      <img
        class="w-full h-48 object-fit"
        src="assets/snow-cool-india-acoustic-foam-sheet.png"
        alt="Anchor Fasteners" />
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">
          <h1 class="text-indigo-600 font-bold flex-auto text-xl font-semibold">
            Acoustic Foam Sheet ( Open Cell )
          </h1>
        </div>
        <p class="text-gray-700 text-sm">
          Acoustic foam is an open celled foam used for acoustic treatment. It
          attenuates airbone sound waves, reducing their amplitude, for the
          purposes of noise reduction or noise control. ... Acoustic foam can be
          attached to walls, ceilings, doors, and other features of a room to
          control noise levels, vibration, and echoes.
        </p>
      </div>

    </div>
  </div>

</section>
<!-- <div class="container mx-auto px-6 py-2 flex justify-between items-center">

  {#each items as item}
    <div class="itemList margin-5 w-full">{item.name} x {item.qty}</div>
  {/each}

</div> -->
