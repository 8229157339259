<!-- <footer class="bg-gray-100">
  <div class="container mx-auto px-6 pt-10 pb-6 border-b">
    <div class="flex flex-wrap">
      <div class="w-full md:w-2/4 text-center md:text-left">
        <h5 class=" mb-6 font-bold">Snow Cool India</h5>
        <ul class="mb-4">
          <li class="mt-2">
            <addres class="hover:underline text-xs ">
              Sales Office: 1400/A,B-4,Gali No.7,Wazir Nagar,Kotla
              Mubarakpur,New Delhi-110003
            </addres>
          </li>
          <li class="mt-2">
            <addres class="hover:underline text-xs ">
              Godown: A-17 Mayapuri industrial area phase -2 New Delhi
            </addres>
          </li>
          <li class="mt-2">
            <addres class="hover:underline text-xs ">
              Mob:
              <a href="tel:9212150861">9212150861 7042678967</a>
             
            </addres>
          </li>
          <li class="mt-2">
            <addres class="hover:underline text-xs ">
              email:
              <a href="mailto:si.snowcool@yahoo.in">si.snowcool@yahoo.in</a>
              <a href="mailto:si.snowcoolindia@gmail.com">
                si.snowcoolindia@gmail.com
              </a>
            </addres>
          </li>
        </ul>
      </div>
    
      <div class="w-full md:w-1/4 text-center md:text-left">
        <h5 class="uppercase mb-6 font-bold">Social</h5>
        <ul class="mb-4">
          <li class="mt-2">
            <a href="#" class="hover:underline text-gray-600 ">Facebook</a>
          </li>
          <li class="mt-2">
            <a href="#" class="hover:underline text-gray-600 ">Linkedin</a>
          </li>
          <li class="mt-2">
            <a href="#" class="hover:underline text-gray-600 ">Twitter</a>
          </li>
        </ul>
      </div>
      <div class="w-full md:w-1/4 text-center md:text-left">
        <h5 class="uppercase mb-6 font-bold">Company</h5>
        <ul class="mb-4">
          <li class="mt-2">
            <a href="#" class="hover:underline text-gray-600 ">Official Blog</a>
          </li>
          <li class="mt-2">
            <a href="#" class="hover:underline text-gray-600 ">About Us</a>
          </li>
          <li class="mt-2">
            <a href="#" class="hover:underline text-gray-600 ">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container mx-auto px-6 py-6 flex justify-between">
    <span class="text-xs">© 2021</span>
    <span class="text-xs">
      Made by
      <a href="https://entebyte.com">entebyte</a>
    </span>
  </div>
</footer> -->

<footer>
  <script
    src="https://kit.fontawesome.com/4db6b32bd3.js"
    crossorigin="anonymous">

  </script>
  <div class="w-full bg-gray-900 text-white">
    <div class="xl:px-40 pb-12 lg:px-20 md:px-10 sm:px-5 px-10">
      <div class="w-full pt-12 flex flex-col sm:flex-row justify-start">
        <div class="w-full sm:w-2/5 pr-6 flex flex-col space-y-4">

          <p class="opacity-60">
            1400/A, B-4, Gali No.7, Wazir Nagar, Kotla Mubarakpur, New
            Delhi-110003
          </p>
        </div>
        <div class="w-full sm:w-2/5 flex flex-col space-y-4">

          <ul class="mb-4">
            <li class="mt-2">
              <p class="font-500">Sales Office</p>
              <addres class="hover:underline text-xs ">
                1400/A,B-4,Gali No.7,Wazir Nagar,Kotla Mubarakpur,New
                Delhi-110003
              </addres>
            </li>
            <!-- <li class="mt-2">

              <p class="font-500">Godown</p>
              <addres class="hover:underline text-xs ">
                A-17 Mayapuri industrial area phase -2 New Delhi
              </addres>
            </li> -->
            <li class="mt-2">
              <p>Mobile</p>
              <addres class="hover:underline text-xs ">

                <a href="tel:7042678967">7042678967</a>
                ,
                <a href="tel:9212150861">9212150861</a>
                <!-- , -->
                <!-- <a href="tel:7042678967">7042678967</a>
              & Tel: 24610861,41010861 -->
              </addres>
            </li>
            <li class="mt-2">
              <p>Email</p>
              <a class="text-xs" href="mailto:si.snowcool@yahoo.in">
                si.snowcool@yahoo.in
              </a>
              <a class="text-xs" href="mailto:si.snowcoolindia@gmail.com">
                si.snowcoolindia@gmail.com
              </a>

            </li>
          </ul>

        </div>
        <div class="w-full sm:w-1/5 flex flex-col space-y-4">
          <a class="opacity-60">About Us</a>
          <a class="opacity-60">Responsibilities</a>
          <a class="opacity-60">Out Services</a>
          <a class="opacity-60">Contact</a>
        </div>
        <div class="w-full sm:w-1/5 pt-6 flex items-end mb-1">
          <div class="flex flex-row space-x-4">
            <i class="fab fa-facebook-f" />
            <i class="fab fa-twitter" />
            <i class="fab fa-instagram" />
            <i class="fab fa-google" />
          </div>
        </div>
      </div>
      <div class="opacity-60 pt-2">
        <p>
          © 2022
          <span class=" text-white">
            Made by
            <a class="" href="https://entebyte.com">Entebyte</a>
          </span>
        </p>
      </div>
    </div>
  </div>
</footer>
